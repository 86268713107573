<template>
	<Toast />
	<Utils ref='Utils' />
	<div class="card p-grid p-fluid" height='100%'>
		<div class="p-col-12 p-md-3">
			<pageNumInput ref='imgsPageNumInput' :op_type_copy='0' @startSearch='getImgs'
				@initialWords='InitialWords' />
			<div class="card-gray" id="result_container" :style='search_box_style' :v-if="imgs_list.length>0">
				<div class="card" v-for="(item, j) of imgs_list" :key="j">
					<i class="img-number">{{j+1}}</i>
					<i v-if='j<=loaded_img_index' class="pi pi-sign-in down-img-button"
						@click="downloadImg(item.img_src)"></i>
					<i class="pi pi-link" style="float: right;margin-right: 0.80em;color: var(--primary-color)"
						@click="copyToClipboard(item.img_src)" title='复制图片链接'></i>
					<img :src='j<=loaded_img_index?item.img_src:loading_img' style="width:100%;"
						@click="selectImgItem($event, item)" />
					<p class="word-item" v-html="item.img_name" @click="selectImgItem($event, item)"></p>
				</div>
				<div class='card' v-if='imgs_list.length==0'>
					<p style="padding: 10px;">没有相关图片</p>
				</div>
			</div>
		</div>
		<div class="p-col-12 p-md-6">
			<div class="card-gray">
				<div class="p-inputgroup" id="search_box">
					<span class="p-input-icon-right">
						<InputText id="search_img_input" style="width: 100%;" :value="search_img_name"
							v-on:keyup.enter="searchImgs" type="text" @input="ImgSearchInputBlur"
							placeholder="查找图片<(￣︶￣)↗[GO!]" />
						<i class="pi pi-times" style="opacity: 0.7;" @click.stop="InitialImgSearch" title="清空输入框" />
					</span>
					<span class="p-inputgroup-addon" @click="searchImgs">
						<i class="pi pi-search" title="查询图片"></i>
					</span>
				</div>
			</div>
			<div class="card-gray" :style='imgs_container_style'>
				<div class='card'>
					<i class="pi pi-sign-in down-img-button" @click="downloadImg(select_item.img_src)"></i>
					<i class="pi pi-link" style="float: right;margin-right: 0.80em;color: var(--primary-color)"
						@click="copyToClipboard(select_item.img_src)" title='复制图片链接'></i>
					<img :src='select_item.img_src' style="width: 100%;" />
				</div>

				<div style="background-color: #FFFFFF;margin-top: 5px;">
					<div class="item-title"><span>图名</span><i class="pi pi-sign-out" style="float: right;"
							@click="openOriginPDF(select_item,0,0)" title='打开原文档'></i><i class="pi pi-book"
							style="float: right;margin-right: 0.80em;" @click="copyToClipboard(select_item.img_name)"
							title='复制文本'></i></div>
					<p class="word-item" id='select_name' v-html="select_item.img_name"></p>
					<p v-if="select_item.show_translate" class="word-item">{{select_item.translate_text}}</p>
					<h6 class="word-item">文章名 / DOI : {{select_item.pdf_name}}</h6>
					<h6 class="word-item">来源期刊 : {{select_item.journal_name}}</h6>
				</div>
			</div>
		</div>

		<div class="p-col-12 p-md-3">
			<div class="card-gray" :style='text_box_style'>
				<h6 style="vertical-align: middle;">图片表述 (｡･∀･)ﾉﾞ</h6>
				<div style="background-color: #ffffff;" v-for="(item, j) of relative_text_list" :key="j">
					<div class="item-title"><span>第{{item.page_num+1}}页</span><i class="pi pi-sign-out"
							style="float: right;" @click="openOriginPDF(item,1,j)" title='打开原文档'></i><i
							class="pi pi-book" style="float: right;margin-right: 0.80em;"
							@click="copyToClipboard(item.img_name)" title='复制文本'></i></div>
					<p class="word-item" v-html="item.img_name"></p>
					<p v-if="item.show_translate" class="word-item">{{item.translate_text}}</p>
				</div>
				<div style="background-color: #ffffff;" v-if='relative_text_list.length==0'>
					<p style="padding: 10px;">没有相关图片表述</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Utils from './Utils.vue';
	import TranslateService from '../service/TransLateService.js';
	import NetWorkService from '../service/NetWorkService.js';
	import pageNumInput from './userComponents/pageNumInput.vue';
	export default {
		name: 'searchJournalIms',
		data() {
			return {
				loading_img: 'assets/layout/images/loading_img.gif',
				loaded_img_index: 1,
				pdf_page_num: null,
				relative_text_list: [],
				pdf_path: '',
				search_img_name: null,
				select_item: {
					'img_name': "图名的文字内容",
					'translate_text': "名字",
					'show_translate': false,
					'page_num': 5,
					'pdf_src': null,
					'img_src': "assets/layout/images/logo-blue.png",
					'doi': "no doi",
					'pdf_name': "文章doi或者名称",
				},
				visibleRight: false,
				//图片列表
				imgs_container_style: {
					"height": "700px",
					"overflow-y": "auto",
					"overflow-x": "hidden",
					"display": "flex",
					"flex-direction": "column",
					"align-items": "right",
					"margin-top": "-5px"
				},
				search_box_style: {
					"height": "700px",
					"overflow-y": "auto",
					"overflow-x": "hidden"
				},
				text_box_style: {
					"height": "700px",
					"overflow-y": "auto",
					"overflow-x": "hidden"
				},
				imgs_list: [],
			}
		},
		components: {
			'Utils': Utils,
			'pageNumInput': pageNumInput,
		},
		created() {
			this.translateService = new TranslateService();
			this.networkService = new NetWorkService();
		},
		mounted() {
			let vm = this;
			vm.isMobile();
			vm.resizeWin();
			//屏幕变化时
			window.onresize = () => {
				return (() => {
					vm.resizeWin();
				})();
			};
			//滚动条事件
			document.getElementById("result_container").addEventListener('scroll', this.handleScroll);
		},
		methods: {
			goToReadPaperPage(item) {
				this.$router.push({
					name: 'readPaper',
					params: {
						pdf_path: this.pdf_path,
						current_page: item.page_num + 1,
						total_page: item.total_page,
						read_single_pdf: item.whole_file,
						pdf_name:item.pdf_name,
					}
				})
			},
			//一键下载图片
			downloadImg(url) {
				this.$refs.Utils.download(url);
			},
			//复制内容到剪切板
			copyToClipboard(src) {
				this.$refs.Utils.copyToClipboard(src);
			},
			handleScroll() {
				// console.log('滚动条滚动');
				//如果已经加载完所有图片，则不再判断
				if (this.loaded_img_index >= 9) return;
				var img_container = document.getElementById("result_container");
				let scrollTop = img_container.scrollTop;
				let offsetTop = img_container.offsetTop;
				// console.log('滑动总高度',scrollTop+offsetTop);
				var container_height = parseInt(this.search_box_style.height.replace('px', ''));
				//滑动距离加上offsetTop大于当前图片对象的offsetTop，则加载该图片，即赋予src值
				var imgs = [...img_container.getElementsByTagName('img')];
				for (var i = this.loaded_img_index + 1; i < imgs.length; i++) {
					var img_offsetTop = imgs[i].offsetTop;
					//如果当前图片加载，则赋值替换
					if ((scrollTop + offsetTop + container_height + 100) > img_offsetTop) {
						this.loaded_img_index = i;
						// this.loaded_img_index=1;
						// console.log('更新显示',this.loaded_img_index);
					} else {
						//如果当前图片都不超过，则后面的图片肯定也不超过
						return;
					}
				}
			},
			//结果框滑动到最上面
			scrollToTop() {
				this.loaded_img_index = 1;
				this.resizeWin();
				document.getElementById('result_container').scrollTop = 0;
			},
			openOriginPDF(item, type, index) {
				this.visibleRight = true;
				if (type == 0) {
					//图名翻译
					this.pdf_path = this.select_item.pdf_src;
					if (this.pdf_path == null) {
						this.visibleRight = false;
						return;
					}
					this.goToReadPaperPage(this.select_item);
				} else {
					//图片表述列表的对象翻译
					this.pdf_path = this.relative_text_list[index].pdf_src;
					this.goToReadPaperPage(this.relative_text_list[index]);
				}
			},
			closeTranslate(select_item, type, index) {
				if (type == 0) {
					//图名翻译
					this.select_item.show_translate = false;
				} else {
					//图片表述列表的对象翻译
					this.relative_text_list[index].show_translate = false;
				}
			},
			translateselectImgItem(item, type, index) {
				if (type == 0) {
					//图名翻译
					if (this.select_item.translate_text !== '') {
						this.select_item.show_translate = true;
						return;
					}
				} else {
					if (this.relative_text_list[index].translate_text !== '') {
						this.relative_text_list[index].show_translate = true;
						return;
					}
				}
				let sentents = item.img_name.replace(/<.*?>/ig, "");
				this.translate(sentents, type, index);
			},
			async translate(content, type, index) {
				this.$appState.processing = true;
				var resp = await this.$refs.Utils.translateSents(content);
				if (type == 0) {
					//图名翻译
					this.select_item.translate_text = resp.data.translate_text;
					this.select_item.show_translate = true;
				} else {
					//图片表述列表的对象翻译
					this.relative_text_list[index].translate_text = resp.data.translate_text;
					this.relative_text_list[index].show_translate = true;
				}
				this.$appState.processing = false;

			},
			selectImgItem(event, item) {
				this.select_item = item;
				this.searchImgExplain(item);
			},
			//查询图片的解释
			async searchImgExplain(item) {
				this.$appState.processing = true;
				this.processing = true;
				var resp = await this.$refs.Utils.getExplainOfImgs(item);
				this.relative_text_list = resp.data.relative_text_list;
				this.processing = false;
				this.$appState.processing = false;
			},
			InitialImgSearch() {
				document.getElementById("search_img_input").value = '';
			},
			//输入
			ImgSearchInputBlur() {
				var word_input = document.getElementById("search_img_input");
				var keyword = word_input.value;
				if (!keyword) {
					this.InitialImgSearch();
					return;
				}
				this.search_img_name = keyword;
			},
			//点击搜索框刷新页面
			searchImgs() {
				this.$refs.imgsPageNumInput.initialSearch();
			},
			//查询图片
			async getImgs() {
				this.$appState.processing = true;
				this.processing = true;
				this.imgs_list = [];
				var word_input = document.getElementById("search_img_input");
				var search_words = word_input.value;
				var resp = await this.$refs.Utils.getImgs(search_words, this.$refs.imgsPageNumInput.current_page_num);
				// console.log(resp.data);
				this.imgs_list = resp.data.imgs_list;
				this.$refs.imgsPageNumInput.total_page_num = resp.data.total_page;

				this.scrollToTop();
				this.processing = false;
				this.$appState.processing = false;
			},
			// 添加判断方法
			isMobile() {
				this.flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				this.current_page = 1;
				if (this.flag === null) {
					// console.log("pc端");
					this.rightNavShow = true;
					this.resizeWin();
				} else {
					this.imgs_container_style.height = "300px";
				}
			},
			//屏幕变化调整样式
			resizeWin() {
				this.center_width = document.documentElement.scrollWidth;
				if (this.center_width <= 750) {
					this.imgs_container_style.height = "auto";
					this.search_box_style.height = "400px";
					this.text_box_style.height = "auto";
				} else {
					var windowHeight = document.documentElement.clientHeight;
					var main_height = windowHeight - 160 + 'px';
					this.imgs_container_style.height = windowHeight - 208 + 'px';
					this.search_box_style.height = windowHeight - 208 + 'px';
					this.text_box_style.height = main_height;
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	::v-deep(.p-multiselect) {
		min-width: 15rem;
	}

	.multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	::v-deep(.multiselect-custom .p-multiselect-label) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	.multiselect-custom .country-item.country-item-value span.flag {
		width: 17px;
	}

	.country-item {
		display: flex;
		align-items: center;
	}

	.country-item span.flag {
		width: 18px;
		height: 12px;
		margin-right: .5rem;
	}

	.multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}


	.item-title {
		color: var(--primary-color);
		padding: 3px 3px 3px 8px;
	}

	.word-item {
		padding: 3px 3px 3px 8px;
		margin-bottom: 5px;
		margin-top: 0px;
	}

	// .word-item:hover {
	// 	background-color: #1fa1fc;
	// 	color: #ffffff;
	// }


	.down-img-button {
		color: var(--primary-color);
		float: right;
		transform: rotate(90deg);
	}

	.img-number {
		float: left;
		font-size: 18px;
		margin-left: 5px;
		font-weight: 700;
		// color:var(--primary-color);
		opacity: 0.5;
	}
</style>
